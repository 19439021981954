<template>
	<div>
		<!-- Scenes -->
		<div v-if="scenes.length > 0" class="padding-top-md">
			<ion-list>
				<ion-item-group>
					<ion-item-divider>
						<ion-label>Scenes</ion-label>
					</ion-item-divider>
					<ion-item v-for="scene in scenes" :key="scene" button detail :href="'/admin-scene/'+scene.id">
						<div tabindex="0"></div>
						{{scene.name}}, {{scene.state}} <span class="text-medium margin-left-sm">#{{scene.id}}</span>
					</ion-item>
				</ion-item-group>
			</ion-list>
		</div>
		<!-- States -->
		<div v-if="states.length > 0" class="padding-top-md">
			<ion-list>
				<ion-item-group>
					<ion-item-divider>
						<ion-label>States</ion-label>
					</ion-item-divider>
					<ion-item v-for="state in states" :key="state" button detail :href="'/admin-scene/'+state.id">
						<div tabindex="0"></div>
						{{state.name}} <span class="text-medium margin-left-sm">#{{state.id}}</span>
					</ion-item>
				</ion-item-group>
			</ion-list>
		</div>
		<!-- Regions -->
		<div v-if="regions.length > 0" class="padding-top-md">
			<ion-list>
				<ion-item-group>
					<ion-item-divider>
						<ion-label>Regions</ion-label>
					</ion-item-divider>
					<ion-item v-for="region in regions" :key="region" button detail :href="'/admin-scene/'+region.id">
						<div tabindex="0"></div>
						{{region.name}} <span class="text-medium margin-left-sm">#{{region.id}}</span>
					</ion-item>
				</ion-item-group>
			</ion-list>
		</div>
		<!-- Countries -->
		<div v-if="countries.length > 0" class="padding-top-md">
			<ion-list>
				<ion-item-group>
					<ion-item-divider>
						<ion-label>Countries</ion-label>
					</ion-item-divider>
					<ion-item v-for="country in countries" :key="country" button detail :href="'/admin-scene/'+country.id">
						<div tabindex="0"></div>
						{{country.name}} <span class="text-medium margin-left-sm">#{{country.id}}</span>
					</ion-item>
				</ion-item-group>
			</ion-list>
		</div>
	</div>
</template>

<script>
import { IonList, IonItemGroup, IonItemDivider, IonItem, IonLabel } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
//import { image, trash } from 'ionicons/icons';
import apiClient from '../services/api';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'AdminSceneList',
	props: [],
	components: {
		IonList, IonItemGroup, IonItemDivider, IonItem, IonLabel
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const scenes = ref([]);
		const states = ref([]);
		const regions = ref([]);
		const countries = ref([]);

		onMounted(() => {
			getScenesByType('metro')
			getScenesByType('state')
			getScenesByType('region')
			getScenesByType('country')
		})

		function getScenesByType(sceneType) {
			//presentLoading()
			apiClient.get('/api/get/scenes/type/' + sceneType, {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				// Scene
				if(sceneType == 'metro') {
					scenes.value = response.data
				}
				// State
				if(sceneType == 'state') {
					states.value = response.data
				}
				// Region
				if(sceneType == 'region') {
					regions.value = response.data
				}
				// Country
				if(sceneType == 'country') {
					countries.value = response.data
				}

			}).catch(error => {
				console.log(error)  
			});      
		}

		function editScene(id) {
			router.push('/admin-scene/'+id)
		}

		return {
			authUser, scenes, states, regions, countries, editScene, router
		}
	},
});
</script>