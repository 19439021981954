<template>
	<div>
		<!-- 
			Backend Errors 
		-->
		<div v-if="isError === true">
			<div v-for="error in errors" :key="error">
				<div v-for="el in error" :key="el">
					<ion-item>
						<div tabindex="0"></div><!-- bug fix for safari 14 -->
						<ion-text color="danger">{{el}}</ion-text>
					</ion-item>
				</div>
			</div>
		</div>
		<!-- 
			Image 
		-->
		<ion-item v-if="imageUrl != null" class="padding-top-md">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-img :src="imageUrl" />
		</ion-item>
		<!-- 
			Scene Type 
		-->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-label position="stacked">Scene Type</ion-label>
			<ion-select placeholder="Select One" v-model="sceneType">
				<ion-select-option value="metro">City or metro area</ion-select-option>
				<ion-select-option value="state">State</ion-select-option>
				<ion-select-option value="region">Region</ion-select-option>
				<ion-select-option value="country">Country</ion-select-option>
			</ion-select>
		</ion-item>
		<!--
			SceneType has been selected 
		-->
		<div v-if="sceneType != null && sceneType != ''">
			<!-- 
				Name 
			-->
			<ion-item v-if="sceneType != 'state'">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="name" type="text" placeholder="Name"></ion-input>
			</ion-item>
			<!-- 
				Slug
			-->
			<ion-item v-if="sceneType == 'metro'">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="slug" type="text" placeholder="Hashtag"></ion-input>
			</ion-item>
			<!-- 
				Abbreviation 
			-->
			<ion-item v-if="sceneType == 'country'">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="abbreviation" type="text" placeholder="Abbreviation"></ion-input>
			</ion-item>
			<!-- 
				State 
			-->
			<ion-item v-if="sceneType != 'region' && sceneType != 'country'">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">State</ion-label>
				<ion-select placeholder="Select One" v-model="state">
					<ion-select-option v-for="item in states" :key="item" :value="item.abbreviation">
						{{item.full}}
					</ion-select-option>
				</ion-select>
			</ion-item>
			<!-- 
				Region 
			-->
			<ion-item v-if="sceneType == 'metro'">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">Region</ion-label>
				<ion-select placeholder="Select One" v-model="region">
					<ion-select-option v-for="item in regions" :key="item" :value="item.slug">
						{{item.name}}
					</ion-select-option>
				</ion-select>
			</ion-item>
			<!-- 
				Country 
			-->
			<ion-item v-if="sceneType != 'country'">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">Country</ion-label>
				<ion-select placeholder="Select One" v-model="country">
					<ion-select-option v-for="item in countries" :key="item" :value="item.slug.toUpperCase()">
						{{item.name}}
					</ion-select-option>
				</ion-select>
			</ion-item>
			<!-- 
				Zip Code 
			-->
			<ion-item v-if="sceneType == 'metro'">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="zipCode" type="text" placeholder="Zip code"></ion-input>
			</ion-item>
			<!-- 
				Status 
			-->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">Status</ion-label>
				<ion-select placeholder="Select One" v-model="status">
					<ion-select-option :value="1">Published</ion-select-option>
					<ion-select-option :value="0">Unpublished</ion-select-option>
				</ion-select>
			</ion-item>
			<!-- 
				Actions 
			-->
			<ion-item lines="none">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-button v-if="imageUrl === null" slot="start" expand="full" size="small" color="light" @click="takePicture()">
					<ion-icon :icon="image"></ion-icon>
				</ion-button>
				<ion-button v-if="imageUrl != null" slot="start" expand="full" size="small" color="danger" @click="imageUrl = null">
					<ion-icon :icon="trash"></ion-icon>
				</ion-button>
				<ion-button v-if="isValidated === true" slot="end" expand="full" size="small" color="primary" @click.prevent="storeScene()">
					Post
				</ion-button>
			</ion-item>
		</div>
	</div>
</template>

<script>
import { IonItem, IonButton, IonText, IonIcon, IonImg, IonSelect, IonSelectOption, IonInput, IonLabel, loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import { image, trash } from 'ionicons/icons';
import apiClient from '../services/api';
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;

export default defineComponent({
	name: 'SceneForm',
	props: [],
	components: {
		IonItem, IonButton, IonText, IonIcon, IonImg, IonSelect, IonSelectOption, IonInput, IonLabel
	},
	setup() {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const isError = ref(false);
		const errors = ref([]);
		const imageUrl = ref(null);
		const name = ref(null);
		const sceneType = ref(null);
		const state = ref(null);
		const region = ref(null);
		const country = ref('US');
		const zipCode = ref(null);
		const status = ref(1);
		const states = ref([]);
		const regions = ref([]);
		const countries = ref([]);
		const abbreviation = ref(null);
		const isValidated = ref(false);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }
		const slug = ref(null);
		const stateFull = ref(null);

		onMounted(() => {
			getStates()
			getScenesByType('region')
			getScenesByType('country')
		})

		function getStates() {
			apiClient.get('/api/get/states',
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				states.value = response.data
			}).catch(error => {
				console.log(error)
			}); 			
		}

		function getScenesByType(val) {
			apiClient.get('/api/get/scenes/type/' + val, {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				console.log(response)
				if(val == 'region') {
					regions.value = response.data
				}
				if(val == 'country') {
					countries.value = response.data
				}
			}).catch(error => {
				console.log(error)  
			});      
		}

		async function takePicture() {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					imageUrl.value = image.dataUrl;
					// can be set to the src of an image now
					console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		}
		function validateForm() {
			// SceneType has been selected
			if(sceneType.value != null) {
				// Metro
				if(sceneType.value == 'metro') {
					// Name
					if(name.value != null && name.value != '') {
						// Slug
						if(slug.value != null) {
							// State
							if(state.value != null) {
								// zipCode
								if(zipCode.value != null && zipCode.value != '') {
									isValidated.value = true
								} 
								// zipCode has not been entered
								else {
									isValidated.value = false
								}							
							} 
							// State has not been entered
							else {
								isValidated.value = false
							}							
						} 
						// Slug has not been entered
						else {
							isValidated.value = false
						}
					} 
					// Name has not been entered
					else {
						isValidated.value = false
					}
				}
				// State
				if(sceneType.value == 'state') {
					// State
					if(state.value != null) {
						getStateFull()
						// country
						if(country.value != null) {
							isValidated.value = true
						} 
						// country has not been selected
						else {
							isValidated.value = false
						}							
					} 
					// State has not been selected
					else {
						isValidated.value = false
					}
				}
				// Region
				if(sceneType.value == 'region') {
					// Name
					if(name.value != null && name.value != '') {
						// country
						if(country.value != null) {
							isValidated.value = true
						} 
						// country has not been entered
						else {
							isValidated.value = false
						}							
					} 
					// Name has not been entered
					else {
						isValidated.value = false
					}
				}
				// Country
				if(sceneType.value == 'country') {
					country.value = null
					// Name
					if(name.value != null && name.value != '') {
						// abbreviation
						if(abbreviation.value != null && abbreviation.value != '') {
							isValidated.value = true
						} 
						// abbreviation has not been entered
						else {
							isValidated.value = false
						}							
					} 
					// Name has not been entered
					else {
						isValidated.value = false
					}
				}
			} 
			// SceneType has not been selected
			else {
				isValidated.value = false
			}
		}

		function storeScene() {
			if(sceneType.value != 'metro') {
				if(sceneType.value == 'state') {
					name.value = stateFull.value
					slug.value = stateFull.value
				}
				if(sceneType.value == 'region') {
					slug.value = name.value
					region.value = slug.value
				}
				if(sceneType.value == 'country') {
					slug.value = abbreviation.value
					country.value = abbreviation.value.toUpperCase()
				}
			}
			presentLoading()
			apiClient.post('/api/post/scene', 
			{
				scene_type: sceneType.value,
				name: name.value,  
				state: state.value, 
				region: region.value, 
				country: country.value,
				zip_code: zipCode.value,
				image: imageUrl.value,
				abbreviation: abbreviation.value,
				status: status.value,
				slug: slug.value,
				state_full: stateFull.value
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			})
			.then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'You have added a scene.'
					openToast()
					isError.value = false
					errors.value = []
					resetForm()
				}
				// Fail 
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You are not authorized to do this.'
					openToast()
				}
				// Fail Auth
				if(response.data.message == 'fail-slug') {
					toastMessage.value = 'That hashtag is already taken.'
					openToast()
				}
			})
			.catch(error => {
				// credentials didn't match
				isError.value = true
				errors.value = error.response.data.errors
			});        	
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function resetForm() {
			sceneType.value = null
			name.value = null
			state.value = null
			region.value = null
			country.value = 'US'
			zipCode.value = null
			abbreviation.value = null
			status.value = 1
			imageUrl.value = null
			isError.value = false
			errors.value = []
			toastMessage.value = null
			slug.value = null	
			stateFull.value = null
		}

		function getStateFull() {
			if(states.value.length > 0) {
				for(var i=0;i<states.value.length;i++) {
					if(states.value[i].abbreviation == state.value) {
						stateFull.value = states.value[i].full
					}
				}
			}
		}

		return {
			authUser, isError, errors, imageUrl, image, trash, name, sceneType, state, region, country, zipCode, status, states, regions, countries, takePicture, abbreviation, isValidated, validateForm, storeScene, slug, toastMessage, stateFull
		}
	},
	watch: {
		sceneType: function() {
			this.validateForm()
		},
		name: function() {
			this.validateForm()
		},
		state: function() {
			this.validateForm()
		},
		region: function() {
			this.validateForm()
		},
		country: function() {
			this.validateForm()
		},
		zipCode: function() {
			this.validateForm()
		},
		abbreviation: function() {
			this.validateForm()
		}
	}
});
</script>